import React from "react";
import { Col, Row } from "reactstrap";
import ImageCheckBox from "./ImageCheckBox";

import threePerson from "../assets/img/klein-gezin.png";
import fourPerson from "../assets/img/gezin.png";
import fifthPerson from "../assets/img/groot-gezin.png";
import singleIcon from "../assets/img/single.png";
import duoIcon from "../assets/img/duo.png";
import logo from "../assets/img/logo.png";

const FiveQuestion = ({
  onNext,
  handleAnswers,
  answers,
  details,
  setDetails,
  handleInputChange,
}) => {
  const handleNext = (val) => {
    handleAnswers(val);
    onNext();
  };
  const questionFive = [
    {
      label: "Alleenstaand",
      icon: singleIcon,
      value: "3916",
    },
    {
      label: "Samenwonend",
      icon: duoIcon,
      value: "3919",
    },
    {
      label: "3 personen",
      icon: threePerson,
      value: "3922",
    },
    {
      label: "4 personen",
      icon: fourPerson,
      value: "3925",
    },
    {
      label: "Groot gezin",
      icon: fifthPerson,
      value: "3928",
    },
  ];
  return (
    <div>
      <div className="question-section">
        <p className="question-section__num">Vraag 5 van 5</p>
        <Row>
          <Col className="question-section__text">
            <span>Wat is je gezinssamenstelling?</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex justify-content-center justify-content-md-start my-4 flex-wrap">
              {questionFive.map((que) => {
                return (
                  <ImageCheckBox
                    key={que.value}
                    details={que}
                    handleNext={handleNext}
                  />
                );
              })}
            </div>
          </Col>
        </Row>
      </div>
      <Row>
          <Col lg='12' className='d-flex justify-content-end question-section__bottom-img'>
          <img  src={logo} alt="" />
          </Col>
        </Row>
    </div>
  );
};

export default FiveQuestion;
