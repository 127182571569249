import React from "react";
import { Button, Col, Input, Row } from "reactstrap";
import logo from "../assets/img/logo.png";

const SecQuestion = ({ onNext, handleAnswers, answers }) => {
  const handleNext = (val) => {
    handleAnswers(val);
    onNext();
  };

  return (
    <>
      <div className="question-section">
        <p className="question-section__num">Vraag 2 van 5</p>
        <Row>
          <Col className="question-section__text">
            <span>Heeft u zonnepanelen?</span>
          </Col>
        </Row>
        <Row>
          <Col md="12" className="mt-3 mx-3 ">
            <Input
              id="first"
              className="question-section__radiobtn"
              type="radio"
              onChange={(e) => {
                handleNext("3976");
              }}
              checked={answers.includes("3976")}
            ></Input>
            <p
              className="mx-2 cursor-pointer question-section__opttext"
              onClick={() => {
                handleNext("3976");
              }}
            >
              Ja, ik heb zonnepanelen
            </p>
          </Col>
          <Col md="12" className="mx-3">
            <Input
              id="sec"
              className="question-section__radiobtn"
              type="radio"
              checked={answers.includes("3979")}
              onChange={(e) => {
                handleNext("3979");
              }}
            ></Input>
            <p
              className="mx-2 cursor-pointer question-section__opttext"
              onClick={() => {
                handleNext("3976");
              }}
            >
              Nee, ik heb geen zonnepanelen
            </p>
          </Col>
        </Row>
        <Row>
          <Col
            lg="12"
            className="d-flex justify-content-end question-section__bottom-img"
          >
            <img src={logo} alt="" />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SecQuestion;
