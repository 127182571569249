import { Link } from "react-router-dom";
import SavingOpportunities from "../components/SavingOpportunities";
import Tips from "../components/Tips";
import { useState } from "react";
import Header from "../components/Header";

const Home = (props) => {
  return (
    <>
      <Header />
      <SavingOpportunities />{" "}
    </>
  );
};

export default Home;
