import React, { useState } from "react";
import {
  Button,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import PrivacyModal from "./Modals/PrivacyModal";
import TermsModal from "./Modals/TermsModal";

const Footer = ({ paddingBottom }) => {
  const [privacyModal, setPrivacyModal] = useState(false);
  const [termsModal, setTermsModal] = useState(false);

  const privacyToggle = () => {
    setPrivacyModal(!privacyModal);
  };

  const termsToggle = () => {
    setTermsModal(!termsModal);
  };
  return (
    <Container>
      <div
        className={`d-flex justify-content-center align-items-center ${paddingBottom}`}
      >
        <span className="footer-text cursor-pointer" onClick={termsToggle}>
          Actievoorwaarden
        </span>
        <span className="footer-text mx-2 ">|</span>{" "}
        <span className="footer-text cursor-pointer" onClick={privacyToggle}>
          Privacystatement
        </span>
      </div>

      <PrivacyModal privacyModal={privacyModal} privacyToggle={privacyToggle} />
      <TermsModal termsModal={termsModal} termsToggle={termsToggle} />
    </Container>
  );
};

export default Footer;
