import React, { useEffect, useState } from "react";
import { Input, Modal } from "reactstrap";
import BesperingExpert from "./BesperingExpert";
import PopupHeader from "./PopupHeader";
import { FormHeader } from "./FormHeader";

import logo from "../assets/img/logo.png";
import ImageCheckBox from "./ImageCheckBox";

import FirstQuestion from "./FirstQuestion";
import SecQuestion from "./SecQuestion";
import ThirdQuestion from "./ThirdQuestion";
import FourQuestion from "./FourQuestion";
import FiveQuestion from "./FiveQuestion";
import Form from "./Form";
import { useDispatch } from "react-redux";
import { createNewLead } from "../store/actions/LeadActions";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Loader from "./Loader";

import arrowLeft from "../assets/img/arrow-left.png";

const questions = [
  FirstQuestion,
  SecQuestion,
  ThirdQuestion,
  FourQuestion,
  FiveQuestion,
  Form,
];

export const Popup = ({
  isOpen,
  toggle,
  site_custom_name,
  site_custom_url,
}) => {
  const [currentQuestion, setCurrentQuestion] = useState(1); // State for tracking the current question
  const { addressDetails } = useSelector((state) => state.lead);
  const handleNextQuestion = () => {
    if (currentQuestion == 5) {
      setIsLoading(true);
      setTimeout(() => {
        setCurrentQuestion((prevQuestion) => prevQuestion + 1);
        setIsLoading(false);
      }, 2500);
    } else {
      setCurrentQuestion((prevQuestion) => prevQuestion + 1);
    }
  };

  const dispatch = useDispatch();

  const [details, setDetails] = useState({
    language: "nl_NL",
    publisher_id: "",
    site_subid: "",
    firstname: "",
    lastname: "",
    email: "",
    // street: "",
    house_number: "",
    // city: "",
    zip: "",
    phone_number: "",
  });

  const clearStates = () => {
    setDetails({
      language: "nl_NL",
      firstname: "",
      lastname: "",
      email: "",
      // street: "",
      house_number: "",
      // city: "",
      zip: "",
      phone_number: "",
    });
    setAnswers(["3913"]);
  };

  const [answers, setAnswers] = useState(["3913"]);

  const history = useHistory();

  const handleFormSubmit = () => {
    let obj = {
      ...details,
      answers,
      site_custom_name,
      site_custom_url,
    };
    if (addressDetails?.settlement) {
      obj = {
        ...obj,
        city: addressDetails?.settlement,
      };
    }
    if (addressDetails?.street) {
      obj = {
        ...obj,
        street: addressDetails?.street,
      };
    }
    dispatch(
      createNewLead(obj, () => {
        clearStates();
        toggle();
        history.push("/bedankt");
      })
    );
  };
  const [isLoading, setIsLoading] = useState(false);

  const thirdOptions = [
    "3946",
    "3949",
    "3952",
    "3955",
    "3958",
    "3961",
    "3964",
    "3967",
    "3970",
    "3973",
  ];

  const fourOptions = ["3931", "3934", "3937", "3940", "3943"];

  const handlePreviousClick = () => {
    let filteredAnswers = [];
    switch (currentQuestion) {
      case 2:
      case 3:
        filteredAnswers = answers.filter(
          (answer) => answer !== "3976" && answer !== "3979"
        );
        setAnswers(filteredAnswers);
        setCurrentQuestion((prevQuestion) => prevQuestion - 1);
        break;
      case 4:
        filteredAnswers = answers.filter((answer) => {
          return !thirdOptions.includes(answer);
        });
        setAnswers(filteredAnswers);
        setCurrentQuestion((prevQuestion) => prevQuestion - 1);
        break;
      case 5:
        filteredAnswers = answers.filter((answer) => {
          return !fourOptions.includes(answer);
        });
        setAnswers(filteredAnswers);
        setCurrentQuestion((prevQuestion) => prevQuestion - 1);
        break;
      default:
        setCurrentQuestion((prevQuestion) => prevQuestion - 1);
        break;
    }
  };

  useEffect(() => {
    const query = history.location.search;
    if (query) {
      const site_subid = new URLSearchParams(query).get("oa_id");
      const publisher_id = new URLSearchParams(query).get("site");
      setDetails((prev) => ({ ...prev, site_subid, publisher_id }));
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleAnswers = (val) => {
    setAnswers((prev) => [...prev, val]);
  };

  const renderQuestion = () => {
    const QuestionComponent = questions[currentQuestion - 1];
    return (
      <QuestionComponent
        onNext={handleNextQuestion}
        details={details}
        setDetails={setDetails}
        handleInputChange={handleInputChange}
        handleAnswers={handleAnswers}
        answers={answers}
        handleFormSubmit={handleFormSubmit}
        formTitle="U kunt mogelijk honderden euro’s per jaar besparen op energie."
        formDescription="Met wie kunnen onze adviseurs contact opnemen om te bekijken welke
        energieleverancier het beste bij u past?"
      />
    );
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          // setCurrentQuestion(1);
          // clearStates();
          toggle();
        }}
        size="lg"
        centered
        className="custom-modal"
      >
        {currentQuestion != 1 && !isLoading && currentQuestion <= 5 && (
          <div className="d-flex mb-2 mb-md-3">
            <img
              src={arrowLeft}
              onClick={handlePreviousClick}
              className="arrow"
            />
          </div>
        )}

        <BesperingExpert value="BESPARING EXPERT" />

        {isLoading ? (
          <Loader />
        ) : (
          <>
            {currentQuestion <= 5 && <PopupHeader />}

            {currentQuestion <= 5 && (
              <div className="custom-modal__hr my-3 my-md-4"></div>
            )}
            {renderQuestion()}
          </>
        )}
      </Modal>
    </>
  );
};
