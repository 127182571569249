import React, { useState } from "react";
import { FormHeader } from "./FormHeader";
import { Button, Col, Form, Input, Row, Spinner } from "reactstrap";
import arrow from "../assets/img/arrow.png";
import logo from "../assets/img/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Footer from "./Footer";

const FormSection = ({
  details,
  handleInputChange,
  handleFormSubmit,
  formTitle,
  formDescription,
}) => {
  const { addressDetails, loading } = useSelector((state) => state.lead);
  const [errors, setErrors] = useState({
    firstNameError: "",
    lastNameError: "",
    phoneError: "",
    emailError: "",
  });
  const nameRegex = /^([a-zA-Z ]){1,30}$/;
  const phoneRegex = /^0\d{9}$/;
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Check if zip and house number are valid
    const isFirstNameValid = nameRegex.test(details.firstname);
    const isLastNameValid = nameRegex.test(details.lastname);
    const isPhoneValid = phoneRegex.test(details.phone_number);
    const isEmailValid = emailRegex.test(details.email);

    if (!isFirstNameValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        firstNameError: "Voer een geldige voornaam in",
      }));
    }
    if (!isEmailValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        emailError: "Voer een geldige e-mail in",
      }));
    }

    if (!isLastNameValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        lastNameError: "Voer een geldige achternaam in",
      }));
    }
    if (!isPhoneValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneError: "Voer een geldige telefoonnummer in",
      }));
    }

    if (isFirstNameValid && isLastNameValid && isPhoneValid && isEmailValid) {
      handleFormSubmit();
    } else {
      setTimeout(() => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          firstNameError: "",
          lastNameError: "",
          phoneError: "",
          emailError: "",
        }));
      }, 2000);
    }
  };

  return (
    <div>
      <FormHeader title={formTitle} description={formDescription} />
      <Form onSubmit={handleSubmit}>
        <Row className="question-section">
          <Col md="6" className="mt-2">
            <Input
              id=""
              placeholder="Voornaam"
              className="mt-2 "
              name="firstname"
              value={details?.firstname}
              onChange={handleInputChange}
              required
              invalid={errors.firstNameError !== ""}
            ></Input>
            <p className="invalid-feedback mb-0">{errors.firstNameError}</p>
          </Col>
          <Col md="6" className="mt-2">
            <Input
              placeholder="Achternaam"
              name="lastname"
              value={details?.lastname}
              onChange={handleInputChange}
              className="mt-2 "
              required
              invalid={errors.lastNameError !== ""}
            ></Input>
            <p className="invalid-feedback mb-0">{errors.lastNameError}</p>
          </Col>

          <Col md="6" className="mt-2">
            <Input
              id=""
              name="phone_number"
              value={details?.phone_number}
              placeholder="Telefoonnummer"
              onChange={handleInputChange}
              className="mt-2"
              invalid={errors.phoneError !== ""}
            ></Input>
            <p className="invalid-feedback mb-0">{errors.phoneError}</p>
          </Col>
          <Col md="6" className="mt-2">
            <Input
              id=""
              name="email"
              value={details?.email}
              placeholder="E-mail"
              onChange={handleInputChange}
              className="mt-2"
              invalid={errors.emailError !== ""}
            ></Input>
            <p className="invalid-feedback mb-0">{errors.emailError}</p>
          </Col>
        </Row>

        <Button
          className=" custom-btn px-3 
         px-md-5 d-flex justify-content-center 
         align-items-center 
         mt-3 mt-md-4"
          type="submit"
          disabled={loading}
        >
          {loading ? (
            <Spinner />
          ) : (
            <>
              GRATIS BEREKENING{" "}
              <img src={arrow} className=" ml-2 ml-md-4 custom-btn__arrow" />
            </>
          )}
        </Button>
      </Form>

      <Row>
        <Col xs="10" md="11" className="question-section__des text-justify">
          <p className="mt-3">
            <b>Wat gebeurt er met je gegevens? </b> Door op GRATIS Berekening te
            klikken worden je gegevens gebruikt om te berekenen hoeveel je kunt
            besparen op je energiekosten maximaal vier keer per jaar. De
            berekening wordt gratis en vrijblijvend telefonisch gedaan door een
            gecertificeerde energieadviseur van Besparenkan. Wil je dit toch
            niet meer, dan kun je je toestemming intrekken via{" "}
            <span
              className="cursor-pointer"
              onClick={() => {
                window.open("https://besparenkan.nl/afmelden", "_blank");
              }}
            >
              het formulier
            </span>{" "}
            op onze website.
          </p>
          <Footer paddingBottom="pb-0" />
        </Col>
        <Col xs="2" md="1" className="d-flex align-items-end ">
          <img className="question-section__desc-bottom" src={logo} alt="" />
        </Col>
      </Row>
    </div>
  );
};

export default FormSection;
