import Bedankt from "../views/Bedankt";
import Tips from "../components/Tips";
import Home from "../views/Home";
import FiveTips from "../views/FiveTips";
import FiveTipsBedankt from "../views/FiveTipsBedankt";
import FiveTipsContract from "../views/FiveTipsContract";
import FiveTipsTarefCheck from "../views/FiveTipsTarefCheck";
import VastContractBedankt from "../views/VastContractBedankt";
import TarefCheckBedankt from "../views/TarefCheckBedankt";

let routes = [
  {
    path: "/tariefcheck2",
    component: Home,
    layout: "main",
  },
  // {
  //   path: "/5tips",
  //   component: FiveTips,
  //   layout: "main",
  // },
  {
    path: "/bedankt",
    component: Bedankt,
    layout: "main",
  },
  // {
  //   path: "/5tips/bedankt",
  //   component: FiveTipsBedankt,
  //   layout: "main",
  // },
  // {
  //   path: "/vastcontract",
  //   component: FiveTipsContract,
  //   layout: "main",
  // },
  // {
  //   path: "/vastcontract/bedankt",
  //   component: VastContractBedankt,
  //   layout: "main",
  // },
  // {
  //   path: "/tariefcheck",
  //   component: FiveTipsTarefCheck,
  //   layout: "main",
  // },
  // {
  //   path: "/tarefcheckBedankt",
  //   component: TarefCheckBedankt,
  //   layout: "main",
  // },
];
export default routes;
