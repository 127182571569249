import React from "react";
import { Col, Row, Spinner } from "reactstrap";
import BesperingExpert from "./BesperingExpert";
import logo from "../assets/img/logo.png";

const Loader = () => {
  return (
    <div>
      <Row className="question-section">
        <Col lg="12" className="question-section__loader-content">
          <div className="d-flex flex-column">
            <p className="mt-3"> Een moment geduld aub... </p>
            <p className="mb-0 pb-0">Duizenden huishoudens </p>
            <p className="mb-0 pb-0">doen de gratis tariefcheck! </p>
          </div>
        </Col>
      </Row>
      <div className="d-flex justify-content-center align-items-center my-3">
        <Spinner className="loaderSection__spinner mt-5" />
      </div>
      <Row className="">
        <Col lg='12' xs='12' className="d-flex justify-content-end ">
          <img src={logo} alt="" />
        </Col>
      </Row>
    </div>
  );
};

export default Loader;
