import React from "react";

import logo from "../assets/img/logo.png";

const ImageCheckBox = ({ details, handleNext }) => {
  return (
    <div
      className="checkbox-item mr-2 cursor-pointer mt-1"
      onClick={() => {
        handleNext(details?.value);
      }}
    >
      <div className="checkbox-item__img d-flex justify-content-center align-items-center p-2 p-lg-4 position-relative">
        <img src={details?.icon} className="img-fluid" />
      </div>
      <p className="checkbox-item__label mb-0 mt-1 text-center">
        {details?.label}
      </p>
    </div>
  );
};

export default ImageCheckBox;
