import React from "react";
import { Button, Col, Row } from "reactstrap";
import appartmentIcon from "../assets/img/appartement.png";
import rijtjeswoningIcon from "../assets/img/rijtjeswoning.png";
import hoekwoningIcon from "../assets/img/hoekwoning.png";
import kapIcon from "../assets/img/2-onder-1-kap.png";
import vrijstaandIcon from "../assets/img/vrijstaand.png";
import ImageCheckBox from "./ImageCheckBox";
import logo from "../assets/img/logo.png";

const FourQuestion = ({ onNext, handleAnswers }) => {
  const handleNext = (val) => {
    handleAnswers(val);
    onNext();
  };
  const questionFour = [
    {
      label: "Appartement",
      icon: appartmentIcon,
      value: "3931",
    },
    {
      label: "Tussenwoning",
      icon: rijtjeswoningIcon,
      value: "3934",
    },
    {
      label: "Hoekwoning",
      icon: hoekwoningIcon,
      value: "3937",
    },
    {
      label: "2-onder 1 kap",
      icon: kapIcon,
      value: "3940",
    },
    {
      label: "Vrijstaand",
      icon: vrijstaandIcon,
      value: "3943",
    },
  ];

  return (
    <div>
      <div className="question-section">
        <p className="question-section__num">Vraag 4 van 5</p>
        <Row>
          <Col className="question-section__text">
            <span>Welk type woning heeft u?</span>
          </Col>
        </Row>
        <div className="d-flex justify-content-center justify-content-md-start my-4 flex-wrap">
          {questionFour.map((que) => {
            return (
              <ImageCheckBox
                handleNext={handleNext}
                key={que.value}
                details={que}
              />
            );
          })}
        </div>
      </div>
      <Row>
        <Col lg="12" className="d-flex justify-content-end question-section__bottom-img">
          <img className='question-section__bottom-img' src={logo} alt="" />
        </Col>
      </Row>
    </div>
  );
};

export default FourQuestion;
