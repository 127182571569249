import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Container } from "reactstrap";
import Header from "../components/Header";
import FiveTipsHeaderTarefCheck from "../components/FiveTipsHeaderTarefCheck";

const Bedankt = () => {
  const { leadDetails } = useSelector((state) => state.lead);
  useEffect(() => {
    setTimeout(() => {
      let useScript = document.createElement("script");
      useScript.setAttribute(
        !(function (f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = "2.0";
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(
          window,
          document,
          "script",
          "https://connect.facebook.net/en_US/fbevents.js"
        ),
        window.fbq("init", "886236645362028"),
        window.fbq("track", "PageView"),
        window.fbq("track", "Lead")
      );
      document.head.appendChild(useScript);
    }, 4000);
  }, []);

  return (
    <>
      {/* <noscript>
        <img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=388080906527220&ev=PageView&noscript=1"
        />
      </noscript> */}
      <noscript>
        <img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=886236645362028&ev=PageView&noscript=1"
        />
      </noscript>
      {/* <Header /> */}
      <FiveTipsHeaderTarefCheck />
      <Container>
        <div className="expert-card">
          <h2 className="text-center py-3">Bedankt voor uw aanvraag!</h2>

          {leadDetails?.profile && (
            <img
              referrerpolicy="no-referrer-when-downgrade"
              src={`https://republish.besparingexpert.nl/m/5845/b1cfeb9f9d71/?event=6817&unique_conversion_id=${leadDetails?.profile?.id}`}
              style={{
                width: "1px",
                height: "1px",
                border: "0px",
              }}
            ></img>
          )}
        </div>
      </Container>
    </>
  );
};

export default Bedankt;
