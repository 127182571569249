import React from "react";

import headerLogo from "../assets/img/header-logo.png";
import headerBackground from "../assets/img/header-bg.png";
import { Container } from "reactstrap";
import { useHistory } from "react-router-dom";

const Header = () => {
  const history = useHistory();
  return (
    <div className="header">
      <Container className="h-100">
        <div className="d-flex align-items-center justify-content-center h-100 w-100">
          <img
            className="img-fluid mr-2 cursor-pointer"
            src={headerLogo}
            onClick={() => {
              history.push("/tariefcheck");
            }}
          />

          <div>
            <p className="mb-0 header__title">
              BESPARINGEXPERT
              <span className="spacer"></span>
              <span className="mb-0 header__sub-title">
                i.s.m. met BesparenKan
              </span>
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Header;
