import React from "react";
import arrowDown from "../assets/img/arrow-down.png";

export const FormHeader = ({ title, description }) => {
  const createMarkup = () => {
    return { __html: description };
  };

  return (
    <>
      <p className="custom-modal__title mb-0 mt-4">{title}</p>

      <p
        className="custom-modal__form-description paragraph my-2 mb-0"
        dangerouslySetInnerHTML={createMarkup()}
      />
      <img
        src={arrowDown}
        height="100"
        width="100"
        className="paragraph__arrowDown"
        alt=""
      />
    </>
  );
};
