// createLead
import axios from "axios";

import { RepositoryFactory } from "../../repository/RepositoryFactory";
let LeadRepo = RepositoryFactory.get("newLead");

export const createNewLead =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    await dispatch(setLeadLoading(true));
    try {
      let { data } = await LeadRepo.createNewLead(payload);
      if (data) {
        dispatch({
          type: "SET_LEAD_DETAILS",
          payload: data,
        });
        onSuccess();
        await dispatch(setLeadLoading(false));
      } else {
        await dispatch(setLeadLoading(false));
      }
    } catch (e) {
      if (e.response.data.error == "Conflict - duplicate") {
        onSuccess();
      } else {
        alert(e.response.data.error);
      }
      await dispatch(setLeadLoading(false));
    }
  };

const apiKey = "P6JTU52clKYjZca8";

export const getAddressDetails =
  (payload, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch) => {
    try {
      const apiUrl = `https://api.pro6pp.nl/v2/autocomplete/nl?authKey=${apiKey}&postalCode=${payload.zip}&streetNumberAndPremise=${payload.house_number}
    `;
      const { data } = await axios.get(apiUrl);
      dispatch({ type: "SET_ADDRESS_DETAILS", payload: data });
      onSuccess();
    } catch (error) {
      dispatch({ type: "SET_ADDRESS_DETAILS", payload: {} });
      onError();
    }
  };

export const setLeadLoading = (val) => async (dispatch) => {
  dispatch({
    type: "SET_LEAD_LOADING",
    payload: val,
  });
};
