import React from "react";

import headerLogo from "../assets/img/header-logo.png";
import { Container } from "reactstrap";
import { useHistory } from "react-router-dom";
import house from "../assets/img/house.png";
import BesperingExpert from "./BesperingExpert";
const FiveTipsHeaderTarefCheck = () => {
  const history = useHistory();
  return (
    <div className="five-tips-header-contract ">
      <Container>
        <div className="tariefcheck__logo ">
          <BesperingExpert value="BesparenKan" />
        </div>
      </Container>

      {/* <div className="d-flex justify-content-center align-items-center h-100">
        <img src={house} />
      </div> */}
    </div>
  );
};

export default FiveTipsHeaderTarefCheck;
