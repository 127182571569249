import React, { useState } from "react";
import { Button, Col, Form, Input, Row } from "reactstrap";
import arrow from "../assets/img/arrow.png";
import logo from "../assets/img/logo.png";

const ThirdQuestion = ({ onNext, handleAnswers }) => {
  const [selected, setSelected] = useState("");

  const options = [
    { name: "Essent", value: "3946" },
    { name: "Eneco", value: "3949" },
    { name: "Vattenfall", value: "3952" },
    { name: "Budget Energie", value: "3955" },
    { name: "Greenchoice", value: "3958" },
    { name: "Vandebron", value: "3961" },
    { name: "Energiedirect", value: "3964" },
    { name: "OXXIO", value: "3967" },
    { name: "Delta", value: "3970" },
    { name: "Anders", value: "3973" },
  ];

  const handleNext = (val) => {
    handleAnswers(val);
    onNext();
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        handleNext(selected);
      }}
    >
      <div className="question-section">
        <p className="question-section__num">Vraag 3 van 5</p>
        <Row>
          <Col className="question-section__text">
            <span>Wat is je huidige energieleverancier</span>
          </Col>
        </Row>
        <Row>
          <Col md="6 " className="mt-3">
            <Input
              placeholder="Maak een keuze..."
              required
              className=" "
              type="select"
              value={selected}
              onChange={(e) => setSelected(e.target.value)}
            >
              <option value="" disabled>
                Maak een keuze...
              </option>

              {options?.map((option) => {
                return <option value={option.value}>{option.name}</option>;
              })}
            </Input>
          </Col>
        </Row>
        <Button
          className=" custom-btn px-3 
        px-md-5 d-flex justify-content-center 
        align-items-center 
        mt-3 mt-md-4"
          type="submit"
        >
          GA VERDER{" "}
          <img src={arrow} className=" ml-2 ml-md-4 custom-btn__arrow" />
        </Button>
      </div>
      <Row>
        <Col
          lg="12"
          className="d-flex justify-content-end question-section__bottom-img"
        >
          <img src={logo} alt="" />
        </Col>
      </Row>
    </Form>
  );
};

export default ThirdQuestion;
