const initialState = {
  addressDetails: {},
  loading: false,
  leadDetails: {},
};

/*
    Any action related to Profile will go here.
*/

export default function leadReducer(state = initialState, { type, payload }) {
  switch (type) {
    case "SET_ADDRESS_DETAILS":
      return {
        ...state,
        addressDetails: payload,
      };
    case "SET_LEAD_LOADING":
      return {
        ...state,
        loading: payload,
      };
    case "SET_LEAD_DETAILS":
      return {
        ...state,
        leadDetails: payload,
      };

    default:
      return { ...state };
  }
}
