import React from "react";

const PopupHeader = () => {
  return (
    <>
      <p className="custom-modal__title mb-0 mt-4">
        Dit is de gratis, snelle vergelijkingstool van BesparenKan.
      </p>
      <p className="custom-modal__description mt-2 mb-0">
        Goed nieuws: vaste contracten zijn weer beschikbaar! <br /> Welke past
        het beste bij jou?
      </p>
    </>
  );
};

export default PopupHeader;
