import React, { useState } from "react";
import { Button, Col, Container, Form, Input, Row } from "reactstrap";
import arrow from "../assets/img/arrow.png";
import { getAddressDetails } from "../store/actions/LeadActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import logo from "../assets/img/logo.png";

const FirstQuestion = ({ onNext, details, setDetails, handleInputChange }) => {
  const [errors, setErrors] = useState({
    zipError: "",
    houseNumberError: "",
  });

  const zipRegex = /^([0-9]{4}[a-zA-Z]{2})$/;
  const houseRegex = /^[0-9]+$/;
  const dispatch = useDispatch();
  const history = useHistory();

  const [addressNotExists, setAddressNotExits] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Check if zip and house number are valid
    const isZipValid = zipRegex.test(details.zip);
    const isHouseNumberValid = houseRegex.test(details.house_number);

    if (!isZipValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        zipError: "Voer een geldige postcode in",
      }));
    }

    if (!isHouseNumberValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        houseNumberError: "Voer een geldige huisnummer in",
      }));
    }

    if (isZipValid && isHouseNumberValid) {
      dispatch(
        getAddressDetails(
          {
            zip: details?.zip,
            house_number: details?.house_number,
          },
          () => {
            onNext();
          },
          () => {
            setAddressNotExits(true);
            setTimeout(() => {
              setAddressNotExits(false);
            }, 2000);
          }
        )
      );
    } else {
      setTimeout(() => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          zipError: "",
          houseNumberError: "",
        }));
      }, 2000);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="question-section">
        <p className="question-section__num">Vraag 1 van 5</p>
        <Row>
          <Col className="question-section__text">
            <span>Wat is uw postcode en huisnummer?</span>
          </Col>
        </Row>
        <Row>
          <Col md="5" className="mt-3">
            <Input
              className="question-section__input-fields"
              placeholder="Postcode"
              required
              value={details?.zip}
              name="zip"
              onChange={handleInputChange}
              invalid={errors.zipError !== ""}
            ></Input>
            <p className="invalid-feedback mb-0">{errors.zipError}</p>
          </Col>
          <Col md="5" className="mt-3">
            <Input
              className="question-section__input-fields"
              placeholder="Huisnummer"
              required
              name="house_number"
              value={details?.house_number}
              onChange={handleInputChange}
              invalid={errors.houseNumberError !== ""}
            ></Input>
            <p className="invalid-feedback mb-0">{errors.houseNumberError}</p>
          </Col>
        </Row>

        <Button
          className=" custom-btn px-3 
               px-md-5 d-flex justify-content-center 
               align-items-center 
               mt-3 mt-md-4"
          type="submit"
        >
          GA VERDER{" "}
          <img src={arrow} className=" ml-2 ml-md-4 custom-btn__arrow" />
        </Button>
        {addressNotExists && (
          <p className="error-feedback my-1">Adres niet gevonden</p>
        )}

        <Row>
          <Col
            lg="12"
            className="d-flex justify-content-end question-section__bottom-img"
          >
            <img src={logo} alt="" />
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default FirstQuestion;
