import Footer from "../components/Footer";
import Header from "../components/Header";

const Main = (props) => {
  return (
    <>
      <div className="d-flex flex-column " style={{ minHeight: "100vh" }}>
        {/* <Header /> */}
        <div style={{ flexGrow: 1 }}>{props.children}</div>
        <Footer paddingBottom="pb-3" />
      </div>
    </>
  );
};

export default Main;
