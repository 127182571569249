import React, { useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import energysaving from "../assets/img/energysaving.png";

import arrow from "../assets/img/arrow.png";
import { Popup } from "./Popup";
import BesperingExpert from "./BesperingExpert";

const SavingOpportunities = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Container>
      <div className="expert-card">
        <Row>
          <Col xs="12" className="d-flex flex-column align-items-center">
            <BesperingExpert value="BESPARING EXPERT" />
            <p className="mb-0 expert-card__title mt-2">
              Er zijn weer volop vaste energiecontracten: er zijn weer veel
              bespaarmogelijkheden.
            </p>

            <div className="d-flex justify-content-center align-items-center mt-1">
              <span className="expert-card__description">Dennis van Haag</span>{" "}
              <div className="expert-card__dot mx-1"></div>{" "}
              <span className="expert-card__description">1 juni 2023</span>
            </div>
          </Col>

          <Col xs="12">
            <p className="mb-0 expert-card__main-description mt-3 mt-md-5">
              De energiemarkt was de afgelopen maanden een drama. Veel gezinnen
              hebben enorm veel moeten betalen voor hun energiekosten. Gemiddeld
              is er een stijging per huishouden van 86% geweest. Maar daar lijkt
              nu een einde aan te komen: de energiemarkt is weer open!{" "}
              <b>Check met welk energiecontract je voordeliger uit bent. </b>
            </p>
            <img fluid src={energysaving} className="w-100 mt-3 mt-md-5" />
            <p className="mb-0 expert-card__main-description mt-3 mt-md-5">
              Het is officieel aangekondigd: vanaf 1 juni worden er weer vaste
              contracten aangeboden bij de meeste energieleveranciers.
              Controleer daarom zo snel mogelijk of jij geld kan besparen met
              onze tariefcheck. Wij vergelijken de meest scherpe tarieven . Zo
              betaal jij eindelijk niet meer teveel voor je energie!
            </p>

            <Button
              className="custom-btn-saving d-flex justify-content-center align-items-center mt-3 mt-md-4"
              onClick={toggle}
            >
              DOE DE TARIEFCHECK{" "}
              <img src={arrow} className="custom-btn-saving__arrow" />
            </Button>
            <p className="mb-0 custom-btn-saving__description">
              Invullen van de tariefcheck duurt slecht 1 minuut.{" "}
            </p>

            <p className="mb-0 expert-card__main-description mt-3 mt-md-4">
              Hou er ook rekening mee dat het prijsplafond maar tot eind dit
              jaar loopt. Dat betekent dat de energieprijzen een stuk duurder
              kunnen gaan worden vanaf 2023. Je kunt dit voorkomen door het
              afsluiten van een vast contract. Door te kiezen voor een vast
              contract, <b> kies je voor zekerheid.</b> Doe de tariefcheck,{" "}
              <b> zo weet je zeker dat je goed zit.</b>
              {/* Dit voorkom je met het afsluiten
              van een vast contract. Met het oversluiten naar een vast contract,
              <b>kies je voor zekerheid.</b> Met het doen van de tariefcheck,
              <b> kies je voor de beste deal.</b> */}
            </p>

            <p className="mb-0 expert-card__main-description-bold mt-3 mt-md-4">
              “Een groot deel van Nederland wilt de aankomende periode niet voor
              verrassingen komen te staan. Zij kiezen voor zekerheid met
              superscherpe tarieven. Zo loop je geen onnodig risico!”
            </p>

            <Button
              className="custom-btn-saving d-flex justify-content-center align-items-center mt-3 mt-md-4"
              onClick={toggle}
            >
              START DE TARIEFCHECK
              <img src={arrow} className="custom-btn-saving__arrow" />
            </Button>
            <p className="mb-0 custom-btn-saving__description">
              Invullen van de tariefcheck duurt slecht 1 minuut.{" "}
            </p>
          </Col>
        </Row>
      </div>

      <Popup
        isOpen={isOpen}
        toggle={toggle}
        site_custom_url="https://besparingexpert.nl/tariefcheck"
        site_custom_name="besparenkan_tariefcheck"
      />
    </Container>
  );
};

export default SavingOpportunities;
