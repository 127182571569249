import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const TermsModal = ({ termsModal, termsToggle }) => {
  return (
    <Modal
      className="footer-modal"
      isOpen={termsModal}
      toggle={termsToggle}
      size="lg"
      centered
    >
      <ModalHeader toggle={termsToggle}>
        <h5>Algemene voorwaarden</h5>
      </ModalHeader>
      <ModalBody>
        <p>
          Deze actie is een initiatief van Besparenkan (onderdeel van Motto
          Products B.V.). Besparenkan is o.a. een online marketing organisatie
          die voor derden online campagnes ontwikkelt t.b.v. het verzamelen van
          consumenteninformatie die actief interesse tonen in het
          product/aanbieding van de initiatiefnemer.
        </p>
        <p>
          Om deel te nemen aan de actie wordt toestemming verleend aan
          Besparenkan om de klant te benaderen met een voorstel voor een
          overstap naar een andere aanbieder. Hiermee bent u kosteloos klant/lid
          van de bespaarcommunity van Besparenkan. Besparenkan treedt op als
          bemiddelaar tussen de klant en aanbieder en ontvangt daarvoor een
          vergoeding van de aanbieder. Besparenkan heeft bij telefonisch energie
          advies gekozen voor een samenwerking met vijf geselecteerde
          leveranciers, te weten Budget Energie, Energiedirect.nl, Essent,
          Greenchoice en NLE waarvan wij speciaal door Besparenkan bedongen
          tarieven vergelijken met de tarieven die uw huidige leverancier
          momenteel op haar website communiceert. Dit zijn de laagste tarieven
          die Besparenkan u telefonisch kan aanbieden. Voor telefonisch
          internet, tv en bellen, sim-only advies heeft Besparenkan gekozen voor
          een samenwerking met Budget Thuis.
        </p>
        <p>
          Inzage en wijzigingen van uw gegevens
          <br />
          Om misbruik te voorkomen vragen wij u, bij een schriftelijk verzoek
          tot inzage, u adequaat te identificeren, door een kopie van een geldig
          legitimatiebewijs mee te sturen. Vergeet niet om op de kopie uw BSN én
          uw pasfoto af te schermen.
        </p>
        <p>
          Voor eventuele vragen, klachten en/of opmerkingen over deze campagne
          kunt u terecht bij:
        </p>
        <p>
          Telefonisch:
          <br />
          088 0550110
          <br />
          Maandag t/m vrijdag van 09.00 tot 17.00 uur
        </p>
        <p>
          E-mail:
          <br />
          via het{" "}
          <a href="https://www.bespaarkiosk.nl/contact/">contactformulier</a> op
          onze website
        </p>
        <p>
          Post:
          <br />
          Besparenkan
          <br />
          t.a.v. afdeling Communicatie
          <br />
          Pastoor Vonckenstraat 21
          <br />
          6166 CV Geleen
          <br />
          KvK-nummer 14056449
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={termsToggle}
          className="text-white bg-success border-0"
        >
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TermsModal;
